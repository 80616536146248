<template>
  <Page class="page-items page-edit-item">
    <template #title>Modifica offerta</template>

    <template #title-extra>
      <router-link class="btn btn-outline-light" :to="{ name: showItemRouteName }">Annulla modifica</router-link>
    </template>

    <template #default>
      <ItemForm :feedback.sync="feedback" :item="itemToEdit" @submitForm="submit" edit />
    </template>
  </Page>
</template>

<script>

import Page from '@/views/components/Private/Page.vue';
import ItemForm from '@/views/components/Form/ItemForm.vue';

export default {
  components: {
    Page,
    ItemForm,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    itemToEdit: {},
    feedback: null,
  }),
  computed: {
    showItemRouteName () {
      if (this.item.isComing) {
        return 'items.coming.show';
      }

      if (this.item.isExpired) {
        return 'items.expired.show';
      }

      return 'items.show';
    },
  },
  watch: {
    item (newValue) {
      this.updateItemToEdit(newValue);
    },
  },
  methods: {
    updateItemToEdit (item) {
      this.itemToEdit = {
        ...item,
        ...this.itemToEdit,
      };
    },
    submit (itemToEdit) {
      const item = { ...itemToEdit };

      this.$api.updateItem(item.id, item)
        .then(res => {
          this.feedback = true;
          this.$emit('update:item', res.data);
          this.itemToEdit = {};

          let showItemRouteName = 'items.show';
          if (res.data?.isExpired) {
            showItemRouteName = 'items.expired.show';
          } else if (res.data?.isComing) {
            showItemRouteName = 'items.coming.show';
          }
          this.$router.push({ name: showItemRouteName });
        })
        .catch(err => {
          this.feedback = false;
          this.$log.error(err);
        })
      ;
    },
  },
  mounted () {
    this.updateItemToEdit(this.item);
  },
};

</script>
